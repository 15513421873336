
/**
 * Base64编码
 * @param {string} str - 待编码的字符串
 * @returns {string} - 编码后的字符串
 */
export function base64Encode(str: string): string {
  return btoa(str);
}

/**
 * Base64解码
 * @param {string} str - 待解码的字符串
 * @returns {string} - 解码后的字符串
 */
export function base64Decode(str: string): string {
  return atob(str);
}
