import './index.less';
import {useMemo} from 'react';

const Mask = (props: any) => {

  const isIframeLoad = useMemo(() => window.self !== window.top, []);

  return (
    <div className={`mask ${ !isIframeLoad && 'mask-page' }`}>
      {props.children}
    </div>
  );
};

export default Mask;