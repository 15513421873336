import {post} from '@/utils/request';


export function sendCode(phone: string, countryId: number, value?: string, code?: string) {
  return post<{
    result: boolean;
    success: boolean;
    captcha: string;
    captcha_code: string;
  }>('/api/student/send-sms', {
    country_id: countryId,
    mobile: phone,
    captcha_value: value,
    captcha_code: code,
  });
}

export function sendResetPasswordSMS(phone: string, countryId: number, value?: string, code?: string) {
  return post<{
    success: boolean;
    captcha: string;
    captcha_code: string;
  }>('/api/student/reset-password-send-sms', {
    country_id: countryId,
    mobile: phone,
    captcha_value: value,
    captcha_code: code,
  });
}

export function checkResetPasswordSMS(phone: string, countryId: number, code?: string) {
  return post<{
    result: boolean;
  }>('/api/student/check-reset-password-sms', {
    mobile: phone,
    country_id: countryId,
    code,
  });
}

export function sendBindMobileSMS(phone: string, countryId: number, value?: string, code?: string) {
  return post<{
    result: boolean;
    captcha: string;
    captcha_code: string;
  }>('/api/student/send-student-sms', {
    country_id: countryId,
    mobile: phone,
    captcha_value: value,
    captcha_code: code,
  });
}

export function checkBindMobileSMS(phone: string, countryId: number, code?: string) {
  return post<{
    result: boolean;
  }>('/api/student/account-bind-mobile', {
    mobile: phone,
    country_id: countryId,
    code,
  });
}

export function checkPassword(phone: string, countryId: number, password: string, submit_password: string) {
  return post<{
    result: boolean;
  }>('/api/student/reset-password', {
    mobile: phone,
    country_id: countryId,
    password,
    submit_password
  });
}



// 验证码登录
// code: 2001 验证码错误
export function codeLogin(phone: string, countryId: number, code: string, isProlong: boolean) {
  return post<{
    token: string;
  }>('/api/student/login', {
    mobile: phone,
    code,
    is_prolong: isProlong ? 1 : 2,
    country_id: countryId
  });
}

export function checkCode(phone: string, code: string) {
  return post<null>('/api/student/check-sms', {
    mobile: phone,
    code,
  });
}

export function captchaCreate() {
  return post<{
    id: string;
    // image base64 string
    captcha: string;
  }>('/api/captcha/create', {});
}

export function captchaVerify(id: string, code: string) {
  return post<{
    result: boolean;
  }>('/api/captcha/verify', {
    id, code
  });
}


// 是否需要图片验证码
export function needCaptchaAPI(phone: string) {
  return post<{
    is_check: boolean;
  }>('/api/student/is-check-captcha', {
    mobile: phone,
  });
}

export function getCountry() {
  return post<{
    list: Array<{
        id: number;
        name: string;
        code: string;
        emoji: string;
    }>;
  }>('/api/common/get-country-list', {});
}

// 获取生成微信二维码的信息
export function getWechatLoginState() {
  return post<{
    redirect_uri: string;
    code: string;
    app_id: string;
  }>('/api/student/get-wechat-login');
}


export function getWechatBindState() {
  return post<{
    redirect_uri: string;
    code: string;
    app_id: string;
  }>('/api/student/get-wechat-bind');
}

// 轮询获取扫码状态
export function getWechatQrcodeStatus(code: string, is_prolong: boolean) {
  return post<{
    'wechat_id': string;
    'student_id': number;
    'token': string;
    'is_bind_mobile': number;
  }>('/api/student/get-wechat-authorize', {
    code, is_prolong: is_prolong ? 1 : 2,
  });
}


export function getWechatQrcodeBindStatus(code: string) {
  return post<{
    result: 'success' | 'failed' | 'exist' | 'not';
    wechat_id: string;
    wechat_name: string;
  }>('/api/student/get-wechat-bind-authorize', {
    code
  });
}


export function bindMobile(phone: string, countryId: number, code: string, wechatId: string, isProlong: boolean) {
  return post<{
    token: string;
  }>('/api/student/bind-mobile', {
    country_id: countryId,
    mobile: phone,
    code,
    wechat_id: wechatId,
    is_prolong: isProlong ? 1 : 2
  });
}

/**
 * 账号密码登录
 * @param account 
 * @param password 
 * @param isProlong 
 * @returns 
 */
export function accountLogin(account: string, password: string, isProlong: boolean) {
  return post<{
    token: string;
    is_bind_mobile: number;
  }>('/api/student/account-login', {
    account,
    password,
    is_prolong: isProlong ? 1 : 2
  });
}


export const checkToken = () => {
  return post<{token: string;}>('/api/student/check-token', {});
};