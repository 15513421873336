import {base64Encode} from '@/utils/base64';


export interface IWechatConfig {
  self_redirect: boolean;
  id: string;
  appid: string;
  scope: string;
  redirect_uri: string;
  state: string;
  href?: string;
  style?: string;
  lang?: string;
  styletype?: string;
  sizetype?: string;
  bgcolor?: string;
  rst?: string;
}

const wechatInit = (w: Window, t: typeof window.document) => {
  return function (e: IWechatConfig) {
    let r = 'default';
    !0 === e.self_redirect ? r = 'true' : !1 === e.self_redirect && (r = 'false');
    const n = t.createElement('iframe');
    let i = 'https://open.weixin.qq.com/connect/qrconnect?appid=' + e.appid + '&scope=' + e.scope + '&redirect_uri=' + e.redirect_uri + '&state=' + e.state + '&login_type=jssdk&self_redirect=' + r + '&styletype=' + (e.styletype || '') + '&sizetype=' + (e.sizetype || '') + '&bgcolor=' + (e.bgcolor || '') + '&rst=' + (e.rst || '');
    i += e.style ? '&style=' + e.style : '';
    i += e.href ? '&href=' + e.href : '';
    i += 'en' === e.lang ? '&lang=en' : '';
    n.src = i;
    n.frameBorder = '0';
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    n.allowTransparency = 'true';
    n.scrolling = 'no';
    n.width = '300px';
    n.height = '400px';
    const s = t.getElementById(e.id);
    if (!s) return;
    s.innerHTML = '';
    s?.appendChild(n);
  };
};


export const createWechatQR = (id: string, redirect_uri: string, state: string, appId: string, width = 146, onlyQrcode = true) => {

  const customeStyle = `
    .impowerBox .info, .impowerBox .title {
      display: ${onlyQrcode ? 'none' : 'block'};
    }
    .impowerBox .qrcode {
      width: ${width}px!important;
      margin: 0;
      border: 0;
      padding: 0;
    }
  `;


  const customeStyleBase64 = 'data:text/css;base64,' + base64Encode(customeStyle);
  const WxLogin = wechatInit(window, document);
  if (WxLogin) {
    WxLogin?.({
      self_redirect: true,
      id: id,
      appid: appId,
      scope: 'snsapi_login',
      redirect_uri: encodeURIComponent(redirect_uri),
      href: customeStyleBase64,
      state: state,
    });
  }

};

export default createWechatQR;