import {useEffect, useState} from 'react';
import {useInterval} from 'ahooks';
import createWechatQR from '@/wechatUtils';
import {getWechatLoginState, getWechatQrcodeStatus} from '@/apis/login';
import i18n from '@/locales/init';

interface Props {
  tosAgreed: boolean;
  isLong: boolean;
  setIsLong: (isLong: boolean) => void;
  scanQrcode: (token: string, studentId: number, wechatId: string, is_bind_mobile: boolean) => void;
  updateToken: ()=>void;
}

export default function WechatLogin({
  tosAgreed,
  isLong, setIsLong,
  scanQrcode,
  updateToken
}: Props) {
  const [qrcodeId, setQrcodeId] = useState('');

  async function refreshQRCode() {
    const {data: {code, redirect_uri, app_id}} = await getWechatLoginState();
    setQrcodeId(code);
    createWechatQR('qrcode', redirect_uri, code, app_id);
  }

  useEffect(() => {
    refreshQRCode();
  }, []);

  const clear = useInterval(() => {
    getWechatQrcodeStatus(qrcodeId, isLong).then(({data: {wechat_id, token, student_id, is_bind_mobile}}) => {
      if (wechat_id) {
        scanQrcode(token, student_id, wechat_id, is_bind_mobile === 1);
        clear();
      }
    }).catch(err=>{
      console.log(err);
      if (err.msg.match('token')){
        updateToken();
      }
    });
  }, 2000);


  return (
    <>
      <div className="title">{i18n.t('common.dauyan_account_login_with_wechat')}</div>
      <div className="qrcode-img" id="qrcode"></div>
      <div className="flex items-center justify-center mx-auto mt-10 mb-20">
        {/* <span className='checkspan'></span> */}
        <input checked={isLong} onChange={e => setIsLong(e.target.checked)} type="checkbox" className="checkspan"/>
        <span className="checkdesc">{i18n.t('common.dauyan_account_remember_status')}</span>
      </div>
    </>
  );
}