import Mask from '@/components/mask';
import Login from '@/components/login';
import { LogoIcon } from '@/components/common';
import bgImg from '@/assets/bg-overlay.jpg';
import './index.less';
const Modal = () => {
  return (
    <div className="modal">
      <div className='bg' style={{
        backgroundImage: `url(${bgImg})`
      }}></div>
      <div className="logo">
        <LogoIcon/>
      </div>
      <Mask><Login/></Mask>
    </div>
  );
};

export default Modal;
