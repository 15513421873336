import { sendCode as sd, sendResetPasswordSMS, sendBindMobileSMS } from '@/apis/login';
import i18n from '@/locales/init';

const elId = 'captcha-mask';

const removeMask = () => {
  const el = document.getElementById(elId);
  if (el) {
    document.body.removeChild(el);
  }
};

/**
 * 发送验证码
 * @param phone 手机号
 * @param countryId 
 * @param type 0手机登录 1重置密码 2绑定手机
 * @returns 
 */
export default function captcha(phone: string, countryId: number, type?: number) {
  return new Promise((resolve, reject) => {

    const sendCode = !type ? sd : type === 1 ? sendResetPasswordSMS : type === 2 ? sendBindMobileSMS : sd;

    removeMask();
    // 创建蒙版元素
    const mask = document.createElement('div');
    mask.id = elId;
    mask.innerHTML = `
      <div>
        <div class="relative bg-white w-50 rounded shadow overflow-hidden p-6">
            <div id="captcha-loading" class="absolute w-full h-full bg-white flex items-center justify-center">
                <span class="text-xl text-gray-500">${i18n.t('common.dauyan_loading')}</span>
            </div>
            <div class="flex items-center gap-3 m-3">
              <img id="captcha-img" class="text-xs h-14" src="" alt="">           
              <input class="w-20 !h-14 border text-2xl rounded p-3 my-1 focus:outline-0" placeholder="${i18n.t('common.dauyan_account_input_placeholder')}" type="number" id="captcha-mask-input">
            </div>
            <button id="captcha-mask-submit" class="
                rounded w-full flex items-center justify-center bg-[#4A9E81] py-2
                transition-all duration-300 ease-in-out
                hover:bg-[#3E8A6B] focus:outline-0
                text-white
            "><span id="captcha-mask-submit-text">${i18n.t('common.dauyan_submit')}</span></button>
        </div>
        
        <style>
            #captcha-mask{
                position: fixed;
                top: 0;
                left: 0;
                z-index: 9999;
                width: 100vw;
                height: 100vh;
            }
            #captcha-mask>div{
                width: 100%;
                height: 100%;
                background:rgba(0,0,0,.3);
                display: flex;
                align-items: center;
                justify-content: center;
            }
        </style>
      </div>
    `;

    document.body.appendChild(mask);

    function reloadImg(){

      sendCode(phone, countryId).then(res => {

        document.getElementById('captcha-loading')?.remove();
        const imgBase64 = res.data.captcha;
        const captchaId = res.data.captcha_code;
        document.getElementById('captcha-img')?.setAttribute('src', imgBase64);
        document.getElementById('captcha-img')?.setAttribute('data-id', captchaId);

      }).catch((e) => {
        reject(e);
      }); 
      
    
    }
    reloadImg();

    document.getElementById('captcha-img')?.addEventListener('click', reloadImg);

    // 删除蒙版元素
    const submitBtn = document.getElementById('captcha-mask-submit');

    submitBtn?.addEventListener('click', async () => {
      const btn = document.getElementById('captcha-mask-submit-text');
      const value = (document.getElementById('captcha-mask-input') as HTMLInputElement)?.value;

      if (!btn || !value) {
        return;
      }
      btn.innerText = i18n.t('common.dauyan_submiting');

      const code = document.getElementById('captcha-img')?.getAttribute('data-id');
      if (!code) return;

      sendCode(phone, countryId, value, code).then(res => {
        // const {success} = res.data;
        const data = (res.data as any);
        const success = data.success || data.result;
        if (!success) {
          reject(i18n.t('common.dauyan_account_verification_error'));
          return;
        }
        resolve({
          success
        });
      }).catch((e) => {
        reject(e);
      }).finally(() => {
        removeMask();
      });


    });
    
  });
}