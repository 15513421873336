import './runtime-var';

// 是否是本地
export const isRemote: boolean = ((window as any).runtime.env === 'production');

// 版本
export const version: string = (window as any).runtime.version;


export const isProduction: boolean = (window as any).location.origin === 'https://www.dauyan.com';

const currentHost = (window as any).location.href as string;

export let homeUrl: string;
if (currentHost.includes('localhost') || currentHost.includes('127.0.0.1')) {
  homeUrl = 'http://localhost:3000/';
} else if (currentHost.includes('.net')) {
  homeUrl = 'https://www.dauyan.net/';
} else {
  homeUrl = 'https://www.dauyan.com/';
}

export const externalLink: Record<string, Record<string, string>> = {
  // 版权声明
  copyright: {
    'zh-CN': `${homeUrl}link/zh-CN/copyright-statement.html`,
    'en-US': `${homeUrl}link/en-US/copyright-statement.html`,
  },
  // 隐私协议
  privacyPolicy: {
    'zh-CN': `${homeUrl}link/zh-CN/privacy-policy.html`,
    'en-US': `${homeUrl}link/en-US/privacy-policy.html`,
  },
  // 免责声明
  disclaimer: {
    'zh-CN': `${homeUrl}link/zh-CN/disclaimer.html`,
    'en-US': `${homeUrl}link/en-US/disclaimer.html`,
  },
  // 用户协议
  userAgreement: {
    'zh-CN': `${homeUrl}link/zh-CN/user-agreement.html`,
    'en-US': `${homeUrl}link/en-US/user-agreement.html`,
  },
};