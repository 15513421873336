import { CSSProperties, useEffect, useRef, useState } from 'react';
import './index.less';
interface ITab{
  values: string[];
  index: number;
  onChange?: (value:number)=>void;
  className?: string;
  style?:CSSProperties;
}
export const Tab = (props: ITab)=>{
  const {values, index, onChange} = props; 

  const ref = useRef<HTMLDivElement>(null);
  const [activeStyle, setActiveStyle] = useState({left: 6, width: 76});
  useEffect(() => {
    if (ref.current){
      const child = ref.current.childNodes[index + 1] as HTMLDivElement;
      if (child){
        setActiveStyle({left: child.offsetLeft, width: child.offsetWidth});
      }
    }
  }, [index,ref.current]);

  return <div className={`tab ${props.className || ''}`} style={props.style}>
    <div className='tab-con' ref={ref}>
      <div className='active' style={activeStyle}></div>
      {
        values.map((item, i) => (
          <div
            key={i}
            className='tab-item'
            onClick={() => {onChange && onChange(i);}}>
            {item}
          </div>
        ))
      }
    </div>
    
  </div>;
};