import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';
import jsCookie from 'js-cookie';
import LanguageDetector from 'i18next-browser-languagedetector';


import * as enUsTrans from './en-US';
import * as zhCnTrans from './zh-CN';

const LOCALS = ['zh-CN', 'en-US'];

let authCookieDomain: string;
if (window.location.href.includes('localhost') || window.location.href.includes('127.0.0.1')) {
  authCookieDomain = 'localhost';
} else if (window.location.href.includes('.net')) {
  authCookieDomain = '.dauyan.net';
} else {
  authCookieDomain = '.dauyan.com';
}

export function getLanguage() {

  const cookieLang = jsCookie.get('lang');
  if (cookieLang && LOCALS.includes(cookieLang)) {
    return cookieLang;
  }
  const lang = window.navigator.language;
  if (LOCALS.includes(lang)) {
    jsCookie.set('lang', lang, {
      expires: 365,
      domain: authCookieDomain
    });
    return lang;
  }
  return LOCALS[0];
}

console.log('getLanguage', getLanguage());

const resources = {
  'zh-CN': {
    translation: zhCnTrans,
  },
  'en-US': {
    translation: enUsTrans,
  },
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    lng: getLanguage(),
    fallbackLng: 'zh-CN',
    load: 'currentOnly',
    detection: {
      order: ['navigator', 'cookie'],
    },
  });

(window as any).i18n = i18n;

export default i18n;

