import axios, {AxiosRequestConfig, AxiosResponse} from 'axios';
import {getToken} from '@/utils/auth';

interface ResponseData<T = never> {
  code: number;
  data: T;
  msg: string;
}

export async function post<T>(url: string, data: any = {}, config?: AxiosRequestConfig): Promise<ResponseData<T>> {
  return new Promise((resolve, reject) => {

    const token = getToken();
    if (token) {
      config = config || {};
      config.headers = config.headers || {};
      config.headers['Authorization'] = token;
    }

    axios.post(url, data, config).then(res => {
      const resData = res.data as ResponseData;

      if (resData.code !== 200) {
        reject({
          ...resData as ResponseData<T>,
          msg: resData.msg,
          message: resData.msg,
        });
        return;
      }
      resolve(res.data as ResponseData<T>);
    }).catch(e => {
      console.error(e);
      reject(e);
    });
  });
}

