/**
 * ARMs配置
 */
import * as BrowserLogger from '@arms/js-sdk';
import { isRemote, isProduction, version } from '../env';

const PID = 'c17fpe3hwh@d710cba6bb25950';
let logger: { setPage: (arg0: string) => any; } | undefined;

export const updatePage = () => {
  logger && logger.setPage('default');
};

const initLogger = () => {
  logger = BrowserLogger.singleton({
    pid: PID,
    appType: 'web',
    imgUrl: 'https://arms-retcode.aliyuncs.com/r.png?', // 上报地址
    sample: 1, // 采样率
    behavior: true,
    enableSPA: true,
    parseHash: () => 'default',
    useFmp: true,
    sendResource: true,
    enableLinkTrace: true,
    page: 'default',
    release: version,
    environment: isRemote ? (isProduction ? 'prod' : 'pre') : 'local',
  });
};

initLogger();
